import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { findAllAccountantsFilter, findAllFinancialAdvisorsFilter } from "../dashboard/slice";
import { SearchIcon } from "../icons/search";
import { keycloak } from "../keycloak";
import { findAllOperators } from "../operator/slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { CompanyStatusEnum, CompanyStatusMap } from "./dto";
import { findAllCompanies, setAccountantFilter, setBusinessNameFilter, setContabileFilter, setFinancialAdvisorFilter, setOperatorsFilter, setStatusFilter } from "./slice";

export function CompanyFiltersComponent() {
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)
    const operatorState = useAppSelector(state => state.operator)
    const dashboardState = useAppSelector(state => state.dashboard)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllFinancialAdvisorsFilter({
            itemsPerPage: 0,
            page: 0,
            accountant: keycloak.hasRealmRole('accountant') ? (authState.findMeResponse?.id.toString() || '') : '',
            financialAdvisor: '',
            allofall: false
        }))
        if (!keycloak.hasRealmRole('operator')) {
            dispatch(findAllAccountantsFilter({
                itemsPerPage: 0,
                page: 0,
                financialAdvisor: keycloak.hasRealmRole('accountant') ? (authState.findMeResponse?.id.toString() || '') : '',
                accountant: '',
                allofall: false
            }))
        }
        if (keycloak.hasRealmRole('financial-advisor')) {
            dispatch(findAllOperators({ financialAdvisor: companyState.findAllOperatorsFinancialAdvisorFilter, operator: '', page: 0, itemsPerPage: 0 }))
        }
    }, [])

    if (
        dashboardState.findAllFinancialAdvisorsFilterStatus === 'loading' ||
        dashboardState.findAllAccountantsFilterStatus === 'loading'
    ) {
        return (
            <div>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    if (
        dashboardState.findAllFinancialAdvisorsFilterStatus === 'failed' ||
        dashboardState.findAllAccountantsFilterStatus === 'failed'
    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    let financialAdvisorMenuItems: ReactNode[] = []
    let accountantMenuItems: ReactNode[] = []
    let operatorsMenuItems: ReactNode[] = []

    if (
        dashboardState.findAllFinancialAdvisorsFilterStatus === 'successfully' &&
        dashboardState.findAllFinancialAdvisorsFilterResponse !== undefined &&
        dashboardState.findAllFinancialAdvisorsFilterResponse.data !== undefined &&
        dashboardState.findAllFinancialAdvisorsFilterResponse.data.length > 0
    ) {
        let temp = dashboardState.findAllFinancialAdvisorsFilterResponse.data.slice()
        temp.sort((a, b) => a.surname.localeCompare(b.surname)).forEach(financialAdvisor => {
            financialAdvisorMenuItems.push(
                <MenuItem key={financialAdvisor.id} value={financialAdvisor.id}>{financialAdvisor.name + ' ' + financialAdvisor.surname}</MenuItem>,
            )
        })
    }

    if (
        dashboardState.findAllAccountantsFilterStatus === 'successfully' &&
        dashboardState.findAllAccountantsFilterResponse !== undefined &&
        dashboardState.findAllAccountantsFilterResponse.data !== undefined &&
        dashboardState.findAllAccountantsFilterResponse.data.length > 0

    ) {
        let temp =  dashboardState.findAllAccountantsFilterResponse.data.slice()
        temp.sort((a, b) => a.surname.localeCompare(b.surname)).forEach(accountant => {
            accountantMenuItems.push(
                <MenuItem key={accountant.id} value={accountant.id}>{accountant.name + ' ' + accountant.surname}</MenuItem>,
            )
        })
    }

    if (
        operatorState.findAllOperatorStatus === 'successfully' &&
        operatorState.findAllOperatorResponse !== undefined &&
        operatorState.findAllOperatorResponse.data !== undefined &&
        operatorState.findAllOperatorResponse.data.length > 0

    ) {
        let temp = operatorState.findAllOperatorResponse.data.slice()
        temp.sort((a, b) => a.surname.localeCompare(b.surname)).forEach(operator => {
            operatorsMenuItems.push(
                <MenuItem key={operator.id} value={operator.id}>{operator.name + ' ' + operator.surname}</MenuItem>,
            )
        })
    }

    return (
        <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
            <div className="col-12 col-lg p-0 m-0">
                <TextInput
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            dispatch(findAllCompanies(companyState.companyFilters))
                        }
                    }}
                    value={companyState.companyFilters.businessName}
                    onChange={(e) => dispatch(setBusinessNameFilter(e.target.value))}
                    id="dashboard-company-filter"
                    type={"text"}
                    placeholder={"Cerca azienda"}
                    startIcon={<SearchIcon colorBase={""} />}
                />
            </div>
            {
                !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('operator') &&
                <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        value={companyState.companyFilters.accountant}
                        onChange={(e) => {
                            dispatch(setAccountantFilter(e.target.value))
                            dispatch(findAllCompanies({
                                accountant: e.target.value,
                                financialAdvisor: companyState.companyFilters.financialAdvisor,
                                businessName: companyState.companyFilters.businessName,
                                operators: companyState.companyFilters.operators,
                                page: companyState.companyFilters.page,
                                itemsPerPage: companyState.companyFilters.itemsPerPage,
                                status: companyState.companyFilters.status,
                                contabile: companyState.companyFilters.contabile
                            }))
                        }}
                        id={"dashboard-accountant-filter"}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti gli account</MenuItem>,
                                accountantMenuItems
                            ]
                        } />
                </div>
            }
            {
                !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('operator') &&
                <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        value={companyState.companyFilters.contabile}
                        onChange={(e) => {
                            dispatch(setContabileFilter(e.target.value))
                            dispatch(findAllCompanies({
                                accountant: companyState.companyFilters.accountant,
                                financialAdvisor: companyState.companyFilters.financialAdvisor,
                                businessName: companyState.companyFilters.businessName,
                                operators: companyState.companyFilters.operators,
                                page: companyState.companyFilters.page,
                                itemsPerPage: companyState.companyFilters.itemsPerPage,
                                status: companyState.companyFilters.status,
                                contabile: e.target.value
                            }))
                        }}
                        id={"dashboard-contabile-filter"}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti i contabili</MenuItem>,
                                accountantMenuItems
                            ]
                        } />
                </div>
            }
            {
                keycloak.hasRealmRole('financial-advisor') &&
                <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        value={companyState.companyFilters.operators}
                        id={"dashboard-operators-filter"}
                        onChange={(e) => {
                            dispatch(setOperatorsFilter(e.target.value))
                            dispatch(findAllCompanies({
                                accountant: companyState.companyFilters.accountant,
                                financialAdvisor: companyState.companyFilters.financialAdvisor,
                                businessName: companyState.companyFilters.businessName,
                                operators: e.target.value,
                                page: companyState.companyFilters.page,
                                itemsPerPage: companyState.companyFilters.itemsPerPage,
                                status: companyState.companyFilters.status,
                                contabile: companyState.companyFilters.contabile
                            }))
                        }}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti i collaboratori</MenuItem>,
                                operatorsMenuItems
                            ]
                        }
                    />
                </div>
            }
            {
                (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('accountant') || keycloak.hasRealmRole('accountant') || keycloak.hasRealmRole('accountant') || keycloak.hasRealmRole('accountant')) &&
                <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        onChange={(e) => {
                            dispatch(setFinancialAdvisorFilter(e.target.value))
                            dispatch(findAllCompanies({
                                accountant: companyState.companyFilters.accountant,
                                financialAdvisor: e.target.value,
                                businessName: companyState.companyFilters.businessName,
                                operators: companyState.companyFilters.operators,
                                page: companyState.companyFilters.page,
                                itemsPerPage: companyState.companyFilters.itemsPerPage,
                                status: companyState.companyFilters.status,
                                contabile: companyState.companyFilters.contabile
                            }))
                        }}
                        id={"dashboard-financial-advisor-filter"}
                        value={companyState.companyFilters.financialAdvisor}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti i commercialisti</MenuItem>,
                                financialAdvisorMenuItems
                            ]
                        }
                    />
                </div>
            }
            <div className="col-12 col-lg-1 p-0 m-0">
                <SelectComponent
                    id={"dashboard-financial-advisor-filter"}
                    value={companyState.companyFilters.status.length === 2 ? '' : companyState.companyFilters.status[0]}
                    onChange={(e) => {
                        dispatch(setStatusFilter(e.target.value === '' ? [CompanyStatusEnum.Active, CompanyStatusEnum.RegistryPending] : [e.target.value as CompanyStatusEnum.Active | CompanyStatusEnum.RegistryPending]))
                        dispatch(findAllCompanies({
                            accountant: companyState.companyFilters.accountant,
                            financialAdvisor: companyState.companyFilters.financialAdvisor,
                            operators: companyState.companyFilters.operators,
                            itemsPerPage: companyState.companyFilters.itemsPerPage,
                            businessName: companyState.companyFilters.businessName,
                            page: companyState.companyFilters.page,
                            status: e.target.value === '' ? [CompanyStatusEnum.Active, CompanyStatusEnum.RegistryPending] : [e.target.value as CompanyStatusEnum.Active | CompanyStatusEnum.RegistryPending],
                            contabile: companyState.companyFilters.contabile
                        }))
                    }}
                    menuItems={
                        [
                            <MenuItem key='' value=''>Stato</MenuItem>,
                            <MenuItem key={CompanyStatusEnum.Active} value={CompanyStatusEnum.Active}>{CompanyStatusMap.get(CompanyStatusEnum.Active)?.label}</MenuItem>,
                            <MenuItem key={CompanyStatusEnum.RegistryPending} value={CompanyStatusEnum.RegistryPending}>{CompanyStatusMap.get(CompanyStatusEnum.RegistryPending)?.label}</MenuItem>,
                        ]
                    }
                />
            </div>
            <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="row p-0 m-0" style={{ gap: '8px' }}>
                    <div className="col p-0 m-0">
                        <ButtonComponent
                            label={"Pulisci"}
                            onClick={() => {
                                dispatch(setBusinessNameFilter(''))
                                dispatch(setOperatorsFilter(''))
                                if (keycloak.hasRealmRole('financial-advisor')) {
                                    dispatch(setFinancialAdvisorFilter(authState.findMeResponse?.id.toString()))
                                } else {
                                    dispatch(setFinancialAdvisorFilter(''))
                                }
                                if (keycloak.hasRealmRole('accountant')) {
                                    dispatch(setAccountantFilter(authState.findMeResponse?.id.toString()))
                                    dispatch(setContabileFilter(authState.findMeResponse?.id))
                                } else {
                                    dispatch(setAccountantFilter(''))
                                    dispatch(setContabileFilter(''))
                                }
                                dispatch(setStatusFilter([CompanyStatusEnum.Active, CompanyStatusEnum.RegistryPending]))
                                dispatch(findAllCompanies({
                                    page: companyState.companyFilters.page,
                                    itemsPerPage: companyState.companyFilters.itemsPerPage,
                                    businessName: '',
                                    operators: '',
                                    accountant: '',
                                    financialAdvisor: keycloak.hasRealmRole('financial-advisor') && authState.findMeResponse !== undefined ? authState.findMeResponse.id.toString() : '',
                                    status: [CompanyStatusEnum.Active, CompanyStatusEnum.RegistryPending],
                                    contabile: ''
                                }))
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col p-0 m-0">
                        <ButtonComponent label={"Applica"} onClick={() => dispatch(findAllCompanies(companyState.companyFilters))} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                    </div>
                </div>
            </div>
        </div>
    )
}