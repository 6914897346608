import { format } from 'date-fns'
import colors from '../../utils/index.module.scss'
import { UserInfoDTO } from './dto'

interface Props {
    message: string
    date: Date
    sent: boolean
    isBroadcast?: boolean
    sender?: UserInfoDTO
    sameSanderAsPrevious: boolean
}
export function MessageComponent(props: Props) {
    const { message } = props
    const { date } = props
    const { sent } = props
    const { isBroadcast } = props
    const { sender } = props
    const { sameSanderAsPrevious } = props

    const loadSenderData = (sender?: UserInfoDTO): string => {
        if (sender === undefined) {
            return ''
        }
        const firstUserName = sender.role === 'companies' && sender.businessName ? sender.businessName : (sender.name + ' ' + sender.surname)
        return firstUserName
    }

    return (
        <div
            style={{
                padding: '8px 12px 8px 12px',
                backgroundColor: (sent ? colors.primary100 : colors.primary50),
                borderRadius: '12px',
                gap: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%'
            }}
        >
            {
                !sent && isBroadcast &&
                <div className='text text--lg typography--semibold typography-neutral--400 pe-5'>
                    {loadSenderData(sender)}
                </div>
            }
            <div
                style={{
                    gap: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    width: '100%'
                }}
            >
                <span className='text text--lg typography--medium typography-neutral--800'>{message}</span>
                <span className='text text--sm typography--regular typography-neutral--500'>{format(new Date(date), 'HH:mm')}</span>
            </div>
        </div>
    )
}