import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { findAccountantsListAvatarsById, findAllAccountant, setFindAllAccountantStatus } from "../accountant/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { findAllCompanyWithoutPagination } from "../company/slice";
import { FileInputComponent } from "../fileInput";
import { FormGroup } from "../formGroup";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { ModalComponent } from "../modal";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";
import colors from '../utils/index.module.scss';
import { createFinancialAdvisor, findAllFinancialAdvisor, resetCreateFinancialAdvisorRequest, resetErrorCreateFinancialAdvisorEmail, setCreateFinancialAdvisorAdminAccountantId, setCreateFinancialAdvisorRequestBusinessName, setCreateFinancialAdvisorRequestDescription, setCreateFinancialAdvisorRequestEmail, setCreateFinancialAdvisorRequestName, setCreateFinancialAdvisorRequestPhoneNumber, setCreateFinancialAdvisorRequestSurname, setOpenFinancialAdvisorModal } from "./slice";
import { CloseIcon } from "../icons/close";

export function NewFinancialAdvisorModal() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const accountantState = useAppSelector(state => state.accountant)

    let accountantsMenuItems: ReactNode[] = []
    let formData = new FormData();

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (financialAdvisorState.openFinancialAdvisorModal) {
            dispatch(findAllAccountant(accountantState.findAllAccountantFilters))
            setFile(null)
            setAvatarToBase64('')
            dispatch(resetErrorCreateFinancialAdvisorEmail())
        }
    }, [financialAdvisorState.openFinancialAdvisorModal])

    useEffect(() => {
        if (financialAdvisorState.createFinancialAdvisorStatus === 'successfully') {
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
            dispatch(resetCreateFinancialAdvisorRequest())
            dispatch(setOpenFinancialAdvisorModal(false))
            dispatch(findAllCompanyWithoutPagination())
            dispatch(findAllAccountant(accountantState.findAllAccountantFilters))
        }
    }, [financialAdvisorState.createFinancialAdvisorStatus])

    useEffect(() => {
        if (accountantState.findAllAccountantStatus === 'successfully' && accountantState.findAllAccountantResponse !== undefined && accountantState.findAllAccountantResponse.data !== undefined && accountantState.findAllAccountantResponse.data.length > 0 && financialAdvisorState.openFinancialAdvisorModal) {
            dispatch(findAccountantsListAvatarsById(accountantState.findAllAccountantResponse.data.map(accountant => ({ objectId: accountant.avatarObjectId, userId: accountant.userId }))))
            dispatch(setFindAllAccountantStatus('idle'))
        }
    }, [accountantState.findAllAccountantStatus, financialAdvisorState.openFinancialAdvisorModal])


    if (accountantState.findAllAccountantsAvatarStatus === 'successfully' && financialAdvisorState.openFinancialAdvisorModal && accountantState.findAllAccountantResponse !== undefined) {
        accountantState.findAllAccountantResponse.data.slice().sort((a, b) => a.surname.localeCompare(b.surname)).forEach((accountant, index) => {
            const avatar = accountantState.accountantsListAvatarResponse.find(avatar => avatar.userId === accountant.userId)?.objectId
            accountantsMenuItems.push(
                <MenuItem key={'new-financial-advisor-admin-accountant-' + index} value={accountant.id}>
                    <AvatarComponent type={AvatarType.USER} size={AvatarSize.XS} name={accountant.name + ' ' + accountant.surname} src={avatar ?? ''} />
                </MenuItem>
            )
        }
        )
    }

    return (
        <ModalComponent
            open={financialAdvisorState.openFinancialAdvisorModal}
            handleClose={() => {
                dispatch(setOpenFinancialAdvisorModal(false));
                dispatch(resetCreateFinancialAdvisorRequest())
            }}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                {
                    accountantState.findAllAccountantStatus === 'loading' ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}><SpinnerComponent size={"small"} /></div>
                    ) : (
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="text text--lg typography--semibold typography--black">Aggiungi commercialista</span>
                                <ButtonComponent label={""} onClick={() => {
                                    dispatch(setOpenFinancialAdvisorModal(false));
                                    dispatch(resetCreateFinancialAdvisorRequest())
                                }} color={Colors.NEUTRAL} variant={Variant.LINK} size={Size.SM} iconStyle={IconStyle.ONLY} icon={<CloseIcon colorBase={""} />} />
                            </div>
                            <div className="d-flex flex-column align-items-start justify-content-between" style={{ gap: '16px', width: '100%' }}>
                                <div className="w-100 d-flex gap-2"><AvatarComponent src={avatarToBase64} type={AvatarType.SINGLE} size={AvatarSize.MD} /> <FileInputComponent id={"create-accountant-avatar"} onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }} /></div>
                                <TextInput id={"type"} type={"text"} required defaultValue={financialAdvisorState.createFinancialAdvisorRequest.businessName} placeholder={"Business name"} onChange={(e) => dispatch(setCreateFinancialAdvisorRequestBusinessName(e.target.value))} />
                                <TextInput id={"type"} type={"text"} required defaultValue={financialAdvisorState.createFinancialAdvisorRequest.name} placeholder={"Nome"} onChange={(e) => dispatch(setCreateFinancialAdvisorRequestName(e.target.value))} />
                                <TextInput id={"type"} type={"text"} required defaultValue={financialAdvisorState.createFinancialAdvisorRequest.surname} placeholder={"Cognome"} onChange={(e) => dispatch(setCreateFinancialAdvisorRequestSurname(e.target.value))} />
                                <TextInput
                                    startIcon={<MailIcon colorBase="" />}
                                    required
                                    id={"type"}
                                    type={"text"}
                                    placeholder={"Email"}
                                    defaultValue={financialAdvisorState.createFinancialAdvisorRequest.email}
                                    onFocus={e => dispatch(resetErrorCreateFinancialAdvisorEmail())}
                                    infoText={financialAdvisorState.errors.createFinancialAdvisor.email !== undefined ? 'Questo indirizzo email è già in uso' : undefined}
                                    error={financialAdvisorState.errors.createFinancialAdvisor.email !== undefined}
                                    onChange={(e) => dispatch(setCreateFinancialAdvisorRequestEmail(e.target.value))}
                                />
                                <TextInput startIcon={<SmartphoneIcon colorBase="" />} infoText="Inserire da 9 a 10 caratteri" defaultValue={financialAdvisorState.createFinancialAdvisorRequest.phoneNumber} id={"type"} type={"text"} placeholder={"Telefono"} onChange={(e) => dispatch(setCreateFinancialAdvisorRequestPhoneNumber(e.target.value === '' ? null : e.target.value))} />
                                <SelectComponent
                                    id={"create-financial-advisor-admin-select"}
                                    required
                                    defaultValue={financialAdvisorState.createFinancialAdvisorRequest.accountantId}
                                    menuItems={[
                                        <MenuItem
                                            key={'select-admin-accountant'}
                                            value=''>Seleziona l'account...</MenuItem>,
                                        accountantsMenuItems
                                    ]}
                                    label="Account"
                                    onChange={(e) => dispatch(setCreateFinancialAdvisorAdminAccountantId(e.target.value))}
                                />
                                <FormGroup label={"Note"} inputs={[<TextInput id={"type"} type={"text"} placeholder={"Aggiungi qui una descrizione"} multiline onChange={(e) => dispatch(setCreateFinancialAdvisorRequestDescription(e.target.value))} />]} style={"column"} />
                            </div>
                            <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                                <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Annulla"} onClick={() => { dispatch(setOpenFinancialAdvisorModal(false)); dispatch(resetCreateFinancialAdvisorRequest()) }} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} /></div>
                                <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Avvia procedura"}
                                    onClick={() => {
                                        if (file !== null)
                                            formData.append('file', file)
                                        formData.append('financialAdvisorCreationDTO', JSON.stringify(financialAdvisorState.createFinancialAdvisorRequest))
                                        dispatch(createFinancialAdvisor(formData))
                                    }}
                                    color={Colors.PRIMARY}
                                    variant={Variant.SOLID}
                                    size={Size.FIT}
                                    iconStyle={IconStyle.OFF}
                                    disabled={
                                        financialAdvisorState.createFinancialAdvisorRequest.businessName === '' ||
                                        financialAdvisorState.createFinancialAdvisorRequest.name === '' ||
                                        financialAdvisorState.createFinancialAdvisorRequest.surname === '' ||
                                        financialAdvisorState.createFinancialAdvisorRequest.email === '' || (
                                            financialAdvisorState.createFinancialAdvisorRequest.phoneNumber !== undefined &&
                                            financialAdvisorState.createFinancialAdvisorRequest.phoneNumber !== null &&
                                            financialAdvisorState.createFinancialAdvisorRequest.phoneNumber !== '' && (
                                                financialAdvisorState.createFinancialAdvisorRequest.phoneNumber.length < 9 ||
                                                financialAdvisorState.createFinancialAdvisorRequest.phoneNumber.length > 10
                                            )) ||
                                        financialAdvisorState.createFinancialAdvisorRequest.accountantId === ''
                                    }
                                />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </ModalComponent >
    )
}