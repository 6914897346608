import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { SearchIcon } from "../icons/search";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { findAllOperators, setFinancialAdvisorFilter, setOperatorFilter } from "./slice";

export function OperatorFiltersComponent() {
    const operatorState = useAppSelector(state => state.operator)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    let financialAdvisorsMenuItems: ReactNode[] = []

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (keycloak.hasRealmRole('admin'))
            dispatch(findAllFinancialAdvisorWithoutPagination())
    }, [])

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading') {
        return (<SpinnerComponent fullScreen size={"small"} />)
    }

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed') {
        return (<div>Si è verificato un errore</div>)
    }

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0) {
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.slice().sort((a, b) => a.surname.localeCompare(b.surname)).forEach(financialAdvisor => {
            financialAdvisorsMenuItems.push(
                <MenuItem key={'operator-financial-advisor-filter-' + financialAdvisor.id} value={financialAdvisor.id}>{financialAdvisor.name + ' ' + financialAdvisor.surname}</MenuItem>
            )
        })
    }

    return (
        <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0">
            <div className="col-12 col-lg p-0 m-0">
                <TextInput
                    id="operator-accountant-filter"
                    type={"text"}
                    placeholder={"Cerca un collaboratore"}
                    startIcon={<SearchIcon colorBase={""} />}
                    onChange={(e) => dispatch(setOperatorFilter(e.target.value))}
                    value={operatorState.findAllOperatorFilters.operator}
                />
            </div>
            {
                keycloak.hasRealmRole('admin') &&
                <div className="col-12 col-lg p-0 m-0">
                    <SelectComponent
                        id={"operator-financial-advisor-filter"}
                        onChange={(e) => {
                            dispatch(setFinancialAdvisorFilter(e.target.value))
                            dispatch(findAllOperators({
                                itemsPerPage: operatorState.findAllOperatorFilters.itemsPerPage,
                                page: operatorState.findAllOperatorFilters.page,
                                operator: operatorState.findAllOperatorFilters.operator,
                                financialAdvisor: e.target.value
                            }))
                        }}
                        value={operatorState.findAllOperatorFilters.financialAdvisor}
                        menuItems={
                            [
                                <MenuItem key='' value=''>Tutti i commercialisti</MenuItem>,
                                financialAdvisorsMenuItems
                            ]
                        }
                    />
                </div>
            }
            <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="row p-0 m-0" style={{ gap: '8px' }}>
                    <div className="col p-0 m-0">
                        <ButtonComponent
                            label={"Pulisci"}
                            onClick={() => {
                                dispatch(setFinancialAdvisorFilter(''))
                                dispatch(setOperatorFilter(''))
                                dispatch(findAllOperators({ operator: '', financialAdvisor: '', itemsPerPage: operatorState.findAllOperatorFilters.itemsPerPage, page: operatorState.findAllOperatorFilters.page }))
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col p-0 m-0">
                        <ButtonComponent label={"Applica"} onClick={() => dispatch(findAllOperators(operatorState.findAllOperatorFilters))} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                    </div>
                </div>
            </div>
        </div>
    )
}