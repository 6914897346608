import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { editMeOperator, findMe, findOperatorAvatarById, setEditMeOperatorDescription, setEditMeOperatorName, setEditMeOperatorPhoneNumber, setEditMeOperatorRequestActivityNotifications, setEditMeOperatorRequestOperationsNotifications, setEditMeOperatorStatus, setEditMeOperatorSurname, setEditOperatorFindAvatarStatus, setFindMeStatus } from "../auth/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { ErrorPopup } from "../errorPopup";
import { FileInputComponent } from "../fileInput";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { FormGroup } from "../formGroup";
import { HeadingSize } from "../heading/dto";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { LayoutComponent } from "../layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SuccessPopup } from "../successPopup";
import { SwitchComponent } from "../switch";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";

export function EditOperatorView() {
    const dispatch = useAppDispatch()

    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const authState = useAppSelector(state => state.auth)

    let formData = new FormData();
    let financialAdvisorsMenuItems: ReactNode[] = []

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        dispatch(findAllFinancialAdvisorWithoutPagination())
    }, [])

    useEffect(() => {
        if (authState.editMeOperatorStatus === 'successfully') {
            dispatch(findMe())
        }
    }, [authState.editMeOperatorStatus])

    useEffect(() => {
        if (authState.findMeStatus === 'successfully' && authState.findMeResponse !== undefined && authState.findMeResponse.avatarObjectId !== null) {
            dispatch(findOperatorAvatarById(authState.findMeResponse.avatarObjectId))
        }
        dispatch(setFindMeStatus("idle"))
    }, [authState.findMeStatus])

    useEffect(() => {
        if (authState.editOperatorFindAvatarStatus === 'successfully') {
            dispatch(setEditOperatorFindAvatarStatus('idle'))
            if (authState.editOperatorAvatar !== undefined && authState.editOperatorAvatar !== null) {
                setAvatarToBase64(authState.editOperatorAvatar)
            }
        }
    }, [authState.editOperatorAvatar])

    //TODO
    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined) {
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.slice().sort((a, b) => a.surname.localeCompare(b.surname))
            .forEach(financialAdvisor => {
                financialAdvisorsMenuItems.push(
                    <MenuItem value={financialAdvisor.id}>
                        {financialAdvisor.name + ' ' + financialAdvisor.surname}
                    </MenuItem>
                )
            })
    }

    return (
        <LayoutComponent
            headingLabel={'Modifica Collaboratore'}
            showSpinner={
                authState.findMeStatus === 'loading' || authState.editMeOperatorStatus === 'loading' || financialAdvisorState.findFinancialAdvisorsListAvatarsByIdStatus === 'loading' ||
                authState.findMeStatus === 'failed'
            }
            headingButtons={[
                <ButtonComponent
                    key='heading-button-update-operator'
                    label={"Aggiorna"}
                    onClick={() => {
                        if (file !== null)
                            formData.append('file', file)
                        formData.append('operatorUpdateDTO', JSON.stringify(authState.editMeOperatorRequest))
                        dispatch(editMeOperator({ id: authState.findMeResponse !== undefined ? authState.findMeResponse.id.toString() : '', data: formData }))
                    }} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF}
                    disabled={
                        (authState.editMeOperatorRequest.name !== undefined && authState.editMeOperatorRequest.name === '') ||
                        (authState.editMeOperatorRequest.surname !== undefined && authState.editMeOperatorRequest.surname === '') || (
                            (authState.editMeOperatorRequest.phoneNumber !== undefined && authState.editMeOperatorRequest.phoneNumber !== null && authState.editMeOperatorRequest.phoneNumber !== '') &&
                            (authState.editMeOperatorRequest.phoneNumber.length < 9 || authState.editMeOperatorRequest.phoneNumber.length > 10)
                        )
                    }
                />]}
            breadcrumbItems={['Collaboratore', 'Modifica Collaboratore']}
            headingSize={HeadingSize.LG}
        >
            <ErrorPopup
                active={authState.editMeOperatorStatus === 'failed'}
                close={() => dispatch(setEditMeOperatorStatus('idle'))}
                message="Si è verificato un errore durante la modifica del collaboratore"
            />
            <SuccessPopup
                active={authState.editMeOperatorStatus === 'successfully'}
                close={() => dispatch(setEditMeOperatorStatus('idle'))}
                message="Collaboratore modificato"
            />
            <FormGroup label={"Nome"} required
                inputs={[
                    <TextInput id={"operator-edit-name"} defaultValue={authState.findMeResponse?.name} onChange={e => dispatch(setEditMeOperatorName(e.target.value))} key={"operator-edit-name"} type={"text"} placeholder={"nome"} />,
                    <TextInput id={"operator-edit-surname"} defaultValue={authState.findMeResponse?.surname} onChange={e => dispatch(setEditMeOperatorSurname(e.target.value))} key={"operator-edit-surname"} type={"text"} placeholder={"cognome"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Email"}
                inputs={[
                    <TextInput startIcon={<MailIcon colorBase="" />} defaultValue={authState.findMeResponse?.email} disabled id={"operator-edit-email"} key={"operator-edit-email"} type={"text"} placeholder={"email"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Telefono"}
                inputs={[
                    <TextInput infoText="Inserire da 9 a 10 caratteri" startIcon={<SmartphoneIcon colorBase="" />} defaultValue={authState.findMeResponse?.phoneNumber} onChange={e => dispatch(setEditMeOperatorPhoneNumber(e.target.value === '' ? null : e.target.value))} id={"operator-edit-phone"} key={"operator-edit-phone"} type={"text"} placeholder={"telefono"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Avatar"} labelDescription="Verrà visualizzata sul vostro profilo"
                inputs={[
                    <div key={"operator-edit-avatar-upload"} className="d-flex align-items-center justify-content-center gap-2">
                        <AvatarComponent src={avatarToBase64} key={"operator-edit-avatar"} type={AvatarType.SINGLE} size={AvatarSize.XXL} />
                        <FileInputComponent onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }} id={"operator-edit-upload"} key={"operator-edit-upload"} />
                    </div>
                ]}
                style={"row"}
            />
            <FormGroup label={"Bio"} labelDescription="Scrivi una breve introduzione"
                inputs={[
                    <TextInput label="Descrizione" defaultValue={authState.findMeResponse?.description} onChange={e => dispatch(setEditMeOperatorDescription(e.target.value))} id={"operator-edit-bio"} key={"operator-edit-bio"} type={"text"} multiline placeholder={"Un po' di informazioni su di te"} infoText="275 caratteri rimasti" />
                ]}
                style={"row"}
            />
            <FormGroup label={"Commercialista"}
                inputs={[
                    <SelectComponent
                        id={"edit-operator-admin-select"}
                        defaultValue={authState.findMeResponse?.financialAdvisorId?.toString()}
                        menuItems={[financialAdvisorsMenuItems]}
                        disabled
                    />
                ]}
                style={"row"}
            />
            <div className="mt-5">
                <FormGroup label={"Gestione Notifiche"}
                    inputs={[
                        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', gap: 40 }}>
                            <SwitchComponent
                                label="Attività"
                                id={"edit-operator-activity-notifications"}
                                onChange={(_, checked) => dispatch(setEditMeOperatorRequestActivityNotifications(checked))}
                                checked={authState.editMeOperatorRequest.activityNotifications === undefined ? authState.findMeResponse?.activityNotifications : authState.editMeOperatorRequest.activityNotifications}
                            />
                            <SwitchComponent
                                label="Operazioni (messaggi, documenti, conto corrente)"
                                id={"edit-operator-document-notifications"}
                                onChange={(_, checked) => dispatch(setEditMeOperatorRequestOperationsNotifications(checked))}
                                checked={authState.editMeOperatorRequest.operationsNotifications === undefined ? authState.findMeResponse?.operationsNotifications : authState.editMeOperatorRequest.operationsNotifications}
                            />
                        </div>
                    ]}
                    style={"row"}
                />
            </div>
        </LayoutComponent>
    )
}