import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { ErrorPopup } from "../errorPopup";
import { FileInputComponent } from "../fileInput";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { FormGroup } from "../formGroup";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { keycloak } from "../keycloak";
import { ModalComponent } from "../modal";
import { createOperator, findAllOperators, resetCreateOperatorRequest, resetErrorCreateOperatorEmail, setCreateOperatorDescription, setCreateOperatorEmail, setCreateOperatorFinancialAdvisorId, setCreateOperatorName, setCreateOperatorPhoneNumber, setCreateOperatorStatus, setCreateOperatorSurname, setOpenOperatorModal } from "../operator/slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";
import colors from '../utils/index.module.scss';
import { CloseIcon } from "../icons/close";

export function NewOperatorModal() {
    const companyState = useAppSelector(state => state.company)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const authState = useAppSelector(state => state.auth)
    const operatorState = useAppSelector(state => state.operator)

    const dispatch = useAppDispatch()

    let formData = new FormData();

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')


    useEffect(() => {
        if (operatorState.openOperatorModal) {
            if (keycloak.hasRealmRole('financial-advisor')) {
                dispatch(setCreateOperatorFinancialAdvisorId(authState.findMeResponse?.id))
            } else if (!window.location.pathname.includes('operator')) {
                dispatch(setCreateOperatorFinancialAdvisorId(companyState.findCompanyResponse?.financialAdvisorId))
            } else {
                dispatch(findAllFinancialAdvisorWithoutPagination())
            }
        }
    }, [operatorState.openOperatorModal])


    useEffect(() => {
        if (operatorState.createOperatorStatus === 'successfully') {
            if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor')) {
                if (window.location.pathname.includes('company/operation')) {
                    dispatch(findAllOperators({ financialAdvisor: companyState.findAllOperatorsFinancialAdvisorFilter, operator: '', page: 0, itemsPerPage: 0 }))
                } else {
                    dispatch(findAllOperators(operatorState.findAllOperatorFilters))
                }
            }
            dispatch(resetCreateOperatorRequest())
            dispatch(setCreateOperatorStatus('idle'))
            setFile(null)
            setAvatarToBase64('')
            dispatch(setOpenOperatorModal(false))
        }
    }, [operatorState.createOperatorStatus])

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed' ||
        financialAdvisorState.findFinancialAdvisorsListAvatarsByIdStatus === 'failed'
    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    let financialAdvisorMenuItems: ReactNode[] = []

    if (
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0
    ) {
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.slice().sort((a, b) => a.surname.localeCompare(b.surname)).forEach((financialAd, index) => {
            financialAdvisorMenuItems.push(
                <MenuItem value={financialAd.id} key={financialAd.id}>{financialAd.name + ' ' + financialAd.surname}</MenuItem>
            )
        })
    }

    return (
        <ModalComponent
            open={operatorState.openOperatorModal}
            handleClose={() => {
                dispatch(setOpenOperatorModal(false))
                setAvatarToBase64('')
                dispatch(resetErrorCreateOperatorEmail())
                dispatch(resetCreateOperatorRequest())
            }}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                {
                    financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading' ||
                        financialAdvisorState.findFinancialAdvisorsListAvatarsByIdStatus === 'loading' ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}><SpinnerComponent size={"small"} /></div>
                    ) : (
                        <>
                            <ErrorPopup
                                active={operatorState.createOperatorStatus === 'failed'}
                                close={() => dispatch(setCreateOperatorStatus('idle'))}
                                message="Si è verificato un errore durante la creazione del collaboratore"
                            />
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="text text--lg typography--semibold typography--black">Aggiungi collaboratore</span>
                                <ButtonComponent label={""} onClick={() => {
                                    dispatch(setOpenOperatorModal(false))
                                    setAvatarToBase64('')
                                    dispatch(resetErrorCreateOperatorEmail())
                                    dispatch(resetCreateOperatorRequest())
                                }} color={Colors.NEUTRAL} variant={Variant.LINK} size={Size.SM} iconStyle={IconStyle.ONLY} icon={<CloseIcon colorBase={""} />} />
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-between" style={{ gap: '16px', width: '100%' }}>
                                <div className="w-100 d-flex gap-2"><AvatarComponent src={avatarToBase64} type={AvatarType.SINGLE} size={AvatarSize.MD} /> <FileInputComponent id={"create-oeprator-avatar"} onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }} /></div>
                                <TextInput
                                    id={"create-operator-name"}
                                    type={"text"}
                                    required
                                    placeholder={"Nome"}
                                    value={operatorState.createOperatorRequest.name}
                                    onChange={e => dispatch(setCreateOperatorName(e.target.value))}
                                />
                                <TextInput
                                    id={"create-operator-surname"}
                                    type={"text"}
                                    value={operatorState.createOperatorRequest.surname}
                                    required
                                    placeholder={"Cognome"}
                                    onChange={e => dispatch(setCreateOperatorSurname(e.target.value))}
                                />
                                <TextInput
                                    startIcon={<MailIcon colorBase="" />}
                                    id={"create-operator-email"}
                                    type={"text"}
                                    value={operatorState.createOperatorRequest.email}
                                    onFocus={e => dispatch(resetErrorCreateOperatorEmail())}
                                    infoText={operatorState.errors.createOperator.email !== undefined ? 'Questo indirizzo email è già in uso' : undefined}
                                    error={operatorState.errors.createOperator.email !== undefined}
                                    required
                                    placeholder={"Email"}
                                    onChange={e => dispatch(setCreateOperatorEmail(e.target.value))}
                                />
                                <TextInput
                                    startIcon={<SmartphoneIcon colorBase="" />}
                                    id={"create-operator-phone-number"}
                                    type={"text"}
                                    value={operatorState.createOperatorRequest.phoneNumber}
                                    placeholder={"Telefono"}
                                    onChange={e => dispatch(setCreateOperatorPhoneNumber(e.target.value === '' ? null : e.target.value))}
                                />
                                {
                                    keycloak.hasRealmRole('admin') && window.location.pathname.includes('operator') &&
                                    <FormGroup
                                        label={"Commercialista"}
                                        inputs={[
                                            <SelectComponent
                                                id={"create-operator-financial-advisor"}
                                                defaultValue={operatorState.createOperatorRequest.financialAdvisorId.toString()}
                                                onChange={e => dispatch(setCreateOperatorFinancialAdvisorId(e.target.value))}
                                                menuItems={[
                                                    <MenuItem value={''} key='select-financial-advisor-for-operator'>Seleziona un commercialista</MenuItem>,
                                                    financialAdvisorMenuItems
                                                ]}
                                            />]}
                                        style={"column"}
                                    />
                                }
                                <FormGroup
                                    label={"Note"}
                                    inputs={[
                                        <TextInput
                                            id={"create-operator-note"}
                                            value={operatorState.createOperatorRequest.description}
                                            type={"text"}
                                            placeholder={"Aggiungi qui una descrizione"}
                                            multiline
                                            onChange={e => dispatch(setCreateOperatorDescription(e.target.value))}
                                        />]}
                                    style={"column"}
                                />
                            </div>
                            <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                                <div
                                    className="col"
                                    style={{ margin: 0, padding: 0 }}
                                >
                                    <ButtonComponent
                                        label={"Annulla"}
                                        onClick={() => {
                                            dispatch(setOpenOperatorModal(false))
                                            setFile(null)
                                            setAvatarToBase64('')
                                            dispatch(resetCreateOperatorRequest())
                                            dispatch(resetErrorCreateOperatorEmail())
                                        }}
                                        color={Colors.NEUTRAL}
                                        variant={Variant.OUTLINE}
                                        size={Size.FIT}
                                        iconStyle={IconStyle.OFF}
                                    />
                                </div>
                                <div
                                    className="col"
                                    style={{ margin: 0, padding: 0 }}
                                >
                                    <ButtonComponent
                                        label={"Avvia procedura"}
                                        onClick={
                                            () => {
                                                if (file !== null)
                                                    formData.append('file', file)
                                                formData.append('operatorCreationDTO', JSON.stringify(operatorState.createOperatorRequest))
                                                dispatch(createOperator(formData))
                                            }
                                        }
                                        color={Colors.PRIMARY}
                                        variant={Variant.SOLID}
                                        disabled={
                                            operatorState.createOperatorRequest.name === '' ||
                                            operatorState.createOperatorRequest.name === undefined ||
                                            operatorState.createOperatorRequest.surname === '' ||
                                            operatorState.createOperatorRequest.surname === undefined ||
                                            operatorState.createOperatorRequest.email === '' ||
                                            operatorState.createOperatorRequest.email === undefined ||
                                            (
                                                operatorState.createOperatorRequest.phoneNumber !== '' &&
                                                operatorState.createOperatorRequest.phoneNumber !== null &&
                                                operatorState.createOperatorRequest.phoneNumber !== undefined && (
                                                    operatorState.createOperatorRequest.phoneNumber.length < 9 ||
                                                    operatorState.createOperatorRequest.phoneNumber.length > 10)
                                            ) ||
                                            operatorState.createOperatorRequest.financialAdvisorId.toString() === '' ||
                                            operatorState.createOperatorRequest.financialAdvisorId === null ||
                                            operatorState.createOperatorRequest.financialAdvisorId === undefined
                                        }
                                        size={Size.FIT}
                                        iconStyle={IconStyle.OFF}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </ModalComponent >
    )
}